import CarouselBootstrap from "./CarouselBootstrap";
const CardBootstrap = (props) => {
  const badgeColors = props.badgeColors.split(", ");
  const badgesArr = [];
  props.badges.split(", ").forEach((item, i) => {
    badgesArr.push(
      <span
        className={
          i === 0
            ? "badge rounded-pill mr-1 " + badgeColors[i]
            : "badge rounded-pill mx-1 " + badgeColors[i]
        }
      >
        {item}
      </span>
    );
  });

  return (
    <div className="w-100 p-2 d-flex justify-content-center">
      <div className="card">
        <CarouselBootstrap
          screenshots={props.screenshots}
          id={props.id}
        ></CarouselBootstrap>
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p>{badgesArr}</p>
          <p className="card-text"></p>
        </div>
      </div>
    </div>
  );
};
export default CardBootstrap;
