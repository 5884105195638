//Components
import CardBootstrap from "./Components/CardBootstrap";
import Header from "./Components/Header"
import Footer from "./Components/Footer"
import About from "./Components/About"
import Contact from "./Components/Contact"
import Bayes from "./Components/CardDescriptions/Bayes";
import Chess from "./Components/CardDescriptions/Chess";
//Images
import tableNewScreenshot from  "./Images/bayesian_predictions_table_new.png"
import tableExpScreenshot from  "./Images/bayesian_predictions_table_exp.png"
import graphScreenshot from  "./Images/bayesian_predictions_graph.png"
import chessScreenshot from "./Images/chess_project.png"
import chessMovesScreenshot from "./Images/chess_project_moves.png"

function App() {
  return (
    <div className="main-app">
      
      <Header></Header>

      <h1 className="text-center pt-3 pt-md-0">Full-Stack Web Development</h1>
      <main className="my-5">
        <About class="pt-3 pt-md-0"></About>

        <hr id="projects" className="border border-dark border-1"></hr>

        <div className="d-flex flex-column flex-sm-row my-5 bg-custom2 rounded" id="bayes-project">
          <Bayes class="w-100 align-self-center pt-3 pt-md-0" heading="Bayesian Predictions"></Bayes>
          <CardBootstrap id="first-card" linking="true" title="Bayesian Predictions" screenshots={[tableNewScreenshot, tableExpScreenshot, graphScreenshot]} badges="Ruby on Rails, React, AWS, Bootstrap" badgeColors="bg-red, bg-blue, bg-warning, bg-purple"></CardBootstrap>
        </div>
        
        <hr className="border border-dark border-1"></hr>

        <div className="d-flex flex-column flex-sm-row my-5 bg-custom3 rounded" id="chess-project">
          <Chess class="w-100 align-self-center pt-3 pt-md-0" heading="Schach"></Chess>
          <CardBootstrap id="second-card" linking="false" title="Schach" screenshots={[chessScreenshot, chessMovesScreenshot]} badges="Ruby, Testing, RSpec" badgeColors="bg-red, bg-warning, bg-cyan"></CardBootstrap>
        </div>

        <hr className="border border-dark border-1"></hr>
        
        <Contact></Contact>
      </main>

      <Footer></Footer>

    </div>
  );
}

export default App;
