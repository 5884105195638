const Bayes = (props) => {
  return (
    <div className={"mx-5 px-2 text-custom-dark " + props.class}>
      <h2 className="text-success">{props.heading}</h2>
      <p>Dieses Projekt habe ich erstellt um die erlernten Grundlagen des objektorientierten Programmierens zu zementieren. Dafür habe ich die Programmiersprache „Ruby" verwendet.</p>
      <p>Ich habe für die einzelnen Komponenten und Funktionen Tests geschrieben, damit die Funktionalität des Programmes gesichert ist, Fehler einfach gefunden werden können und um die Qualität meines Codes zu verbessern.</p>

    </div>
  );
}

export default Bayes