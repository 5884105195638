import githubLogo from "./../Images/github.svg";

const Footer = () => {
  return (
    <footer className="text-center text-custom-dark mt-5">
      Copyright © 2021{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.github.com/jangoergens"
        className="text-custom-dark text-decoration-none"
      >
        {" "}
        Jan Görgens{" "}
        <img
          id="github-logo"
          className="mb-1"
          src={githubLogo}
          alt="Github Logo"
        ></img>
      </a>
    </footer>
  );
};

export default Footer;
