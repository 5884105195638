import logo from "./../Images/logo.svg"
const Header = () => {
  return (
    <header className="d-flex justify-content-between">
      <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jan-goergens/">
        <img src={logo} alt="Personal Logo"></img>
      </a>
      <nav className="nav align-content-center">
        <li className="nav-item my-auto">
          <a className="nav-link btn text-primary" href="#projects">Projekte</a>
        </li>
        <li className="nav-item my-auto">
          <a className="nav-link btn text-primary" href="#contact">Kontakt</a>
        </li>
      </nav>
    </header>
  );
}

export default Header