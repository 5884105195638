const CarouselBootstrap = (props) => {
  let pictures = [];
  props.screenshots.forEach((item, index) => {
    pictures.push(
      <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
        <a href={item} target="_blank" rel="noopener noreferrer">
          <img src={item} className="d-block w-100 rounded" alt={"Slide " + (index + 1)}></img>
        </a>
      </div>
    )
  })
  return (
    <div id={props.id} className="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
      <div className="carousel-inner">
        {pictures} 
      </div>
      <a className="carousel-control-prev" href={"#" + props.id} role="button" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </a>
      <a className="carousel-control-next" href={"#" + props.id} role="button" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </a>
    </div>

  );
}
export default CarouselBootstrap