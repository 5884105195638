import linkedin from "./../Images/linkedin.svg";
import github from "./../Images/github.svg";

const Contact = (props) => {
  return (
    <div
      id="contact"
      className={
        "text-center text-break align-self-center p-3 bg-gradient-contact text-custom-dark rounded-3 " +
        props.class
      }
    >
      <h2 className="text-custom-dark">Kontakt</h2>
      <h5>
        <b>Email:</b> J.H.Goergens@gmail.com
      </h5>
      <h5>
        <a
          className="p-2 text-decoration-none text-dark"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/jan-goergens/"
        >
          <img
            className="mb-1 mx-2"
            style={{ width: "30px" }}
            src={linkedin}
            alt="LinkedIn Logo"
          ></img>
          LinkedIn
        </a>
        <a
          className="p-2 text-decoration-none text-dark"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.github.com/jangoergens"
        >
          <img
            className="mb-1 mx-2"
            style={{ width: "32px" }}
            src={github}
            alt="Github Logo"
          ></img>
          Github
        </a>
      </h5>
    </div>
  );
};

export default Contact;
