const Bayes = (props) => {
  return (
    <div className={"mx-5 px-2 text-custom-dark " + props.class}>
      <h2 className="text-success">{props.heading}</h2>

      <p>Dieses Projekt dient der akkuraten Kalibrierung der eigenen Prognosen.</p>
      <p>Man kann damit über einen längeren Zeitraum, selbstformulierte Vorhersagen sammeln und diese danach, mithilfe eines mathematischen Models, analysieren lassen.</p>
      <p>Entwickelt wurde die Webseite mithilfe des Ruby on Rails Framework.</p>
      <p>Für das Design wird Bootstrap in Kombination mit SASS und ReactJS benutzt.</p>
      <p>Das Speichern der Datein funktioniert über den Cloud Speicher von AWS (Amazon Web Services).</p>
      <p>Entwickelt wurde das Projekt in Zusammenarbeit mit Jan Christian Refsgaard.</p>
    </div>
  );
}

export default Bayes