import avatar from "../Images/avatar.svg"
const About = (props) => {
  return (
    <div className="d-flex flex-column flex-sm-row align-items-center">
      <div id="about" className={"w-100 text-custom-dark p-1 p-md-5 rounded order-1 order-sm-0 " + props.class}>
        <h2 className="text-success text-center">Über mich</h2>
        <h3 className="text-center">Hallo, ich bin Jan Görgens und ich entwickle seit zwei Jahren Webseiten.</h3>
        <hr></hr>
        <p>Ich habe Erfahrung sowohl im Frontend mit <b>HTML, CSS und Javascript</b>, als auch im Backend mit <b>Ruby, AWS und Docker</b>.</p>
        <p>Ich bin stets dabei, neue Dinge zu lernen und mich weiterzuentwickeln. Meine Bereitschaft, Herausforderungen anzunehmen und problemlösungsorientiert vorzugehen, zeichnet mich aus.</p>
        <p>Dynamische Seiten zu entwickeln, die man am mobilen Endgerät ebenso wie auch am Computer bedienungsfreundlich benutzen kann, gehört zu meinen Stärken.</p>
        <p>Dabei greife ich zurück auf Frameworks wie <b>Bootstrap, ReactJS, Ruby on Rails</b> und viele mehr.</p>
      </div>
      <img id="avatar" className="w-75 h-25 order-0 order-sm-1" src={avatar} alt="Avatar"></img>
    </div>
  );
}

export default About